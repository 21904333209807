import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useExhibitionsStore() {
  var storedCurrentPage = useStoreState(function (state) {
    return state.exhibitions.storedCurrentPage;
  });
  var setStoredCurrentPage = useStoreActions(function (actions) {
    return actions.exhibitions.setStoredCurrentPage;
  });
  var storedSearchQuery = useStoreState(function (state) {
    return state.exhibitions.storedSearchQuery;
  });
  var setStoredSearchQuery = useStoreActions(function (actions) {
    return actions.exhibitions.setStoredSearchQuery;
  });
  var storedSortQuery = useStoreState(function (state) {
    return state.exhibitionsSort.storedSortQuery;
  });
  var setStoredSortQuery = useStoreActions(function (actions) {
    return actions.exhibitionsSort.setStoredSortQuery;
  });
  return {
    storedCurrentPage: storedCurrentPage,
    setStoredCurrentPage: setStoredCurrentPage,
    storedSearchQuery: storedSearchQuery,
    setStoredSearchQuery: setStoredSearchQuery,
    storedSortQuery: storedSortQuery,
    setStoredSortQuery: setStoredSortQuery
  };
}

export { useExhibitionsStore };